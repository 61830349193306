Numbers.formatNodes();

(function () {
	const showAllInfo = $('#showAllInfo'), dropdown = showAllInfo.closest('.dropdown');
	initTooltips(showAllInfo, initTooltips.opts());

	let lastNewsViewInstant = new Date().getTime(), lastNewsViewSent = false;

	function sendLastNewsView() {
		if (lastNewsViewSent) {
			return;
		}
		lastNewsViewSent = true;
		$.get('/aj/site-news-reset?instant=' + lastNewsViewInstant);
	}

	dropdown
		.on('show.bs.dropdown', function () {
			console.log('destroyTooltips');
			destroyTooltips(showAllInfo);
			sendLastNewsView();
		})
		.on('hidden.bs.dropdown', function () {
			console.log('initTooltips');
			initTooltips(showAllInfo, initTooltips.opts());
		});

	dropdown.find('a[data-toggle=close]').click(function () {
		showAllInfo.click();
	});

	new NameSearchWidget('#headerSearch', {
		autoSelectFirst: true
	});
})();
